import React from "react";
import Table from "../../../../src/components/table/Table";

const MergeTwoSortedArraysComplexityAnalysis = () => {
  const tableHeaders = [
    "Approach",
    "Time Complexity",
    "Space Complexity",
    "Remarks"
  ];
  
  const tableData = [
    {
      id : "1",
      columns: [   
        "Naïve Solution",
        "O( (m+n) log (m+n) )",
        "O(1)",
        "Complexity of Sorting the array"
      ]
    },
    {
      id : "2",
      columns: [   
        "Using Two Pointer Technique",
        "O(m+n)",
        "O(1)",
        ""
      ]
    },
  ];

  return (
    <Table headers={tableHeaders} rows={tableData}/> 
  );
}
export default MergeTwoSortedArraysComplexityAnalysis;