import React from "react";
import Table from "../../../../src/components/table/Table";

const TableOperator1sCompliment = () => {
    return (
    <Table headers={tableHeaders} rows={tableData}/> 
    );
}
export default TableOperator1sCompliment;


const tableHeaders = [
    "X",
    "~X",
  ];
  
  const tableData = [
    {
      id : "1",
      columns: [   
        "0",
        "1",
      ]
    },
    {
      id : "2",
      columns: [   
        "1",
        "0",
      ]
    },
  ];