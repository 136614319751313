const youtubeUrl = 'https://www.youtube.com/c/TechnologyStrive'
const faceBookUrl = 'https://www.facebook.com/technologystrive'
const githubUrl = 'https://github.com/TechnologyStrive'
const email = 'technologystrive17@gmail.com'

const config = {
  siteUrl : process.env.GATSBY_SITE_URL,
  gatsby: {
    pathPrefix: '/',
    gaTrackingId: 'UA-205118875-1',
    googlePublisherId: 'pub-1531009424550706',
    trailingSlash: false,
  },
  siteMetadata: {
    title: 'Technology Strive | Tutorial on computer science',
    description: 'Tutorials on computer science and technologies.',
    ogImage: null,
    docsLocation: '',
    favicon: process.env.GATSBY_SITE_URL+'/images/TS_LOGO_192.png',
  },
  social: {
    youtubeUrl: youtubeUrl,
    githubUrl: githubUrl,
    facebookUrl: faceBookUrl,
    facebookAppId: "136167549316981",
    email: email,
    author: 'TechnologyStrive',
  },
  amazonAffiliate: {
    AccessKey: 'AKIAJGIJZZ5D2265TCPA',
    SecretKey: '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',
    PartnerTag: 'technolo012c3-21',
  },
  header: {
    title: 'TechnologyStrive',
    logo: {
      link: '/',
      image: process.env.GATSBY_SITE_URL+'/images/TS_LOGO_192.png'
    }, 
    links: [
      {
        text: 'DSA',
        link: "/dsa"
      },
      {
        text: 'Flowcharts',
        link: "/flow-chart"
      },
      {
        text: 'Java',
        link: "/java-programs"
      },
      {
        text: 'Golang',
        link: "/golang"
      },
    ],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    links: [
      { 
        text: 'Youtube Channel', 
        link: youtubeUrl
      },
    ],
    collapsedNav: [
    ],
    frontLine: false,
    ignoreIndex: false,
    // title:
    //   "<a href='/'>graphql </a><div class='greenCircle'></div><a href='https://hasura.io/learn/graphql/react/introduction/'>react</a>",
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Technology Strive',
      short_name: 'TechnologyStrive',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
