import TableOperators from "../../../../content/utils/tables/dsa/BitManipulation_Operators";
import TableOperatorAND from "../../../../content/utils/tables/dsa/BitManipulation_OperatorAnd";
import TableOperatorOR from "../../../../content/utils/tables/dsa/BitManipulation_OperatorOR";
import TableOperatorXOR from "../../../../content/utils/tables/dsa/BitManipulation_OperatorXOR";
import TableOperator1sCompliment from "../../../../content/utils/tables/dsa/BitManipulation_Operator1Compliment";
import * as React from 'react';
export default {
  TableOperators,
  TableOperatorAND,
  TableOperatorOR,
  TableOperatorXOR,
  TableOperator1sCompliment,
  React
};