import React, { useEffect } from 'react'
import './ads.css' ;

const AD_DISPLAY = "DISPLAY"
const AD_ARTICLE = "IN_ARTICLE"
const AD_FEED = "IN_FEED"
const AD_MULTIPLEX = "MULTIPLEX"

const className = "adsbygoogle ad_block" ;
const display_color = process.env.NODE_ENV === "development" ? "blue" : "none"
const default_color = process.env.NODE_ENV === "development" ? "grey" : "none"
const article_color = process.env.NODE_ENV === "development" ? "yellow" : "none"
const feed_color = process.env.NODE_ENV === "development" ? "green" : "none"
const multiplex_color = process.env.NODE_ENV === "development" ? "red" : "none"


export const GoogleAdSense = ({location, type}) => {
  // console.log("GoogleAdBlock type", type)
  type = "ABC"
  if (type === "ABC") {
    return (<></>)
  }
  if (type === AD_DISPLAY) {
    return (
      <>
        <DisplayAdBlock/>
      </>
    )
  }else if (type === AD_ARTICLE) {
    return (
      <>
        <InArticleAdBlock/>
      </>
    )
  }else if (type === AD_FEED) {
    return (
      <>
        <InFeedAdBlock/>
      </>
    )
  }else if (type === AD_MULTIPLEX) {
    return (
      <>
        <MultiplexAdBlock/>
      </>
    )
  }else {
    return (
      <>
        <DefaultAdBlock location={location}/>
      </>
    )
  }
}

const DefaultAdBlock = ({ location }) => {

  const path = location === undefined ? '' : location.pathname ;

  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [path])
  
  return (
    <>
      <ins 
        className={className}
        style={{ "display": "block" , textAlign: "center", background: default_color}}
        data-ad-client="ca-pub-1531009424550706"
        data-ad-slot="1015300240"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </>
  )
}



/*
    Copy and paste the ad unit code in between the <body></body> tags of your pages
*/
const DisplayAdBlock = () => {
  return (
      <>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1531009424550706"
          crossOrigin="anonymous"></script>
          <ins className={className}
              // style="display:block"
              style={{ "display": "block", background: display_color}}
              data-ad-client="ca-pub-1531009424550706"
              data-ad-slot="6459198618"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
      </>
  )
}



/*
    Place the ad inside any of your pages that have text. We suggest 2 paragraphs below the start of the article. However, don't place the ad in the sidebar as this can negatively affect performance.
*/
const InArticleAdBlock = () => {
  return (
      <>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1531009424550706"
      crossOrigin="anonymous"></script>
      <ins className={className}
      // style="display:block; text-align:center;"
      style={{ "display": "block" , textAlign: "center", background: article_color}}
      data-ad-layout="in-article"
      data-ad-format="fluid"
      data-ad-client="ca-pub-1531009424550706"
      data-ad-slot="5347065919"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
      </>
  )
}


const InFeedAdBlock = () => {
  return (
  <>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1531009424550706"
      crossOrigin="anonymous"></script>
      <ins className={className}
      //  style="display:block"
      style={{ "display": "block", background: feed_color}}
      data-ad-format="fluid"
      data-ad-layout-key="-et-5u+cd-17-g7"
      data-ad-client="ca-pub-1531009424550706"
      data-ad-slot="6660147586"></ins>
      <script>
      (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
  </>)
}



/*
   Copy and paste the ad unit code in between the <body></body> tags of your pages
*/
const MultiplexAdBlock = () => {
  return (
    <>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1531009424550706"
      crossOrigin="anonymous"></script>
      <ins className={className}
      style={{ "display": "block", background: multiplex_color}}
      data-ad-format="autorelaxed"
      data-ad-client="ca-pub-1531009424550706"
      data-ad-slot="2557003620"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </>
  )
}

const MultiplexAdBlock1 = ({ location }) => {

  const path = location === undefined ? '' : location.pathname ;

  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [path])
  
  return (
    <>
      <ins 
        className="adsbygoogle"
        style={{ "display": "block", background: multiplex_color}}
        data-ad-client="ca-pub-1531009424550706"
        data-ad-slot="3801531095"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </>
  )
}