import * as React from 'react';
import isAbsoluteUrl from 'is-absolute-url';
import config from '../../../config';
import GatsbyLink from 'gatsby-link';

const Link = ({ to, ...props }) => {
  // console.log("url Before", to)
  // const href = isAbsoluteUrl(to) ? to : config.siteUrl+to
  const href = to
  // console.log("url After", href)
  return (
    isAbsoluteUrl(href) ? (
      <a href={href} {...props}>
      {props.children}
    </a>
    ) : 
    (
      <GatsbyLink to={href} {...props} />
    )
  )
}
export default Link;
