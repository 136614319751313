import styled from "@emotion/styled";


export const TagsTitle = styled.span`
  font-size: 14px;
  background: #000000;
  display: inline-block;
  color: #ffffff;
  line-height: 1rem;
  padding: .25rem;
  padding-left: .75rem;
  padding-right: .75rem;
  margin-right: .25rem;
  margin-bottom: .25rem;
  text-transform: uppercase;
  margin-right: 1.5rem;
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,liberation mono,courier new,monospace;
`

export const StyledTagList = styled.ul`
  list-style: none;
  display: inline-block;
  gap: 0.6rem;
  flex-wrap: wrap;
  padding: 0.2rem 0.6rem;
  max-width: 60rem;
`;

export const TagItem = styled.li`
  margin-right: 0.6rem;
  margin-bottom: 0.6rem;
  text-transform: uppercase;
  display: inline-block;
  font-weight: bold;

  a {
    padding: .25rem;
    padding-left: .75rem;
    padding-right: .75rem;
    margin-right: .25rem;
    margin-bottom: .25rem;
    display: inline-block;
    font-size: .75rem;
    line-height: 1rem;
    font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,liberation mono,courier new,monospace;
    border-radius: .25rem;
    background-color: rgb(187 247 208);
    color: rgb(22 101 52);
  }

  a:hover {
      background-color: #FF4F4F;
      color: #ffffff;
  }
`;