import React from "react";
import Table from "../../../../src/components/table/Table";

const LinearVsNonLinearDataStructures = () => {
    return (
    <Table headers={tableHeaders} rows={tableData}/> 
    );
}
export default LinearVsNonLinearDataStructures;


const tableHeaders = [
    "Linear Data Structures",
    "Non-Linear Data Structures",
  ];
  
  const tableData = [
    {
      id : "1",
      columns: [   
        "Elements are related to each other in linear fashion	",
        "Elements form hierarchical order",
      ]
    },
    {
      id : "2",
      columns: [ 
      "Elements can be accessed in single traversal	",
      "All elements are not able to access in single traversal",
      ]
    },
    {
      id : "3",
      columns: [ 
        "Implementation of linear data structure is easy	",
        "Implementation of non-linear data structure is complex",
      ]
    },
    {
      id : "4",
      columns: [ 
        "Memory utilization is not effective",
        "Memory utilization is effective",
      ]
    },
    {
      id : "5",
      columns: [ 
        "Examples: Array, Linked list, Stack, Queue	",
        "Examples: Tree, Graph",
      ]
    },
  ];