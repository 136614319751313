import * as React from 'react';

export const PageLink = ({id, children}) => {
    const href = pageLinkMap[id]
    return (
        <>
        <a  
            href={href} 
            target="_blank" 
            rel="noopener noreferrer"
            // {...rest}
        >{children}</a>
        </>
    )
}


var pageLinkMap = {}

pageLinkMap["UC_DSA"] = '/dsa'
pageLinkMap["UC_DSA_TimeComplexityAnalysis"] = '/dsa/time-complexity-analysis'
pageLinkMap["UC_DSA_NumberSystem"] = '/dsa/number-system'
pageLinkMap["UC_DSA_BitManipulation"] = '/dsa/bit-manipulation'
pageLinkMap["UC_DSA_Factorial"] = '/dsa/factorial'
pageLinkMap["UC_DSA_FibonacciSequence"] = '/dsa/fibonacci-sequence'
pageLinkMap["UC_DSA_GCD_LCM"] = '/dsa/gcd-lcm'
pageLinkMap["UC_DSA_Permutation"] = '/dsa/permutation'

pageLinkMap["UC_DSA_DataStructure"] = '/dsa/data-structure'
pageLinkMap["UC_DSA_Array"] = '/dsa/array'
pageLinkMap["UC_DSA_DynamicArray"] = '/dsa/dynamic-array'
pageLinkMap["UC_DSA_LinkedList"] = '/dsa/linked-list'
pageLinkMap["UC_DSA_Queue"] = '/dsa/queue'
pageLinkMap["UC_DSA_QueueUsingArray"] = '/dsa/queue-using-array'
pageLinkMap["UC_DSA_QueueUsingLL"] = '/dsa/queue-using-linkedlist'
pageLinkMap["UC_DSA_Stack"] = '/dsa/stack'
// pageLinkMap["UC_DSA_Tree"] = '/dsa/tree'
// pageLinkMap["UC_DSA_Graph"] = '/dsa/graph'
pageLinkMap["UC_DSA_StackUsingArray"] = '/dsa/stack-using-array'
pageLinkMap["UC_DSA_StackUsingDynamicArray"] = '/dsa/stack-using-dynamic-array'
pageLinkMap["UC_DSA_StackUsingLinkedList"] = '/dsa/stack-using-linkedlist'

pageLinkMap["UC_DSA_Algorithm"] = '/dsa/algorithm'
pageLinkMap["UC_DSA_Searching"] = '/dsa/searching'
pageLinkMap["UC_DSA_LinearSearch"] = '/dsa/linear-search'
pageLinkMap["UC_DSA_BinarySearch"] = '/dsa/binary-search'
pageLinkMap["UC_DSA_Sorting"] = '/dsa/sorting'
pageLinkMap["UC_DSA_BubbleSort"] = '/dsa/bubble-sort'
pageLinkMap["UC_DSA_SelectionSort"] = '/dsa/selection-sort'
pageLinkMap["UC_DSA_MergeSort"] = '/dsa/merge-sort'
pageLinkMap["UC_DSA_QuickSort"] = '/dsa/quick-sort'
pageLinkMap["UC_DSA_Recursion"] = '/dsa/recursion'
pageLinkMap["UC_DSA_DivideAndConquerApproach"] = '/dsa/divide-and-conquer-approach'
pageLinkMap["UC_DSA_TwoPointerTechnique"] = '/dsa/two-pointer-technique'



export const UC_JavaPrograms = '/java-programs'
export const UC_JP_EvenOdd = '/java-programs/even-odd'
export const UC_JP_ArmstrongNumber = '/java-programs/armstrong-number'
export const UC_JP_ReadInput = '/java-programs/read-input'
export const UC_JP_ReadFile = '/java-programs/read-file'


pageLinkMap["UC_JavaPrograms"] = UC_JavaPrograms
pageLinkMap["UC_JP_EvenOdd"] = UC_JP_EvenOdd
pageLinkMap["UC_JP_ArmstrongNumber"] = UC_JP_ArmstrongNumber
pageLinkMap["UC_JP_ReadInput"] = UC_JP_ReadInput
pageLinkMap["UC_JP_ReadFile"] = UC_JP_ReadFile

export const UC_JavaMultithreading = '/java-multithreading'
export const UC_JM_InterThreadCommunication = '/java-multithreading/inter-thread-communication'

pageLinkMap["UC_JavaMultithreading"] = UC_JavaMultithreading
pageLinkMap["UC_JM_InterThreadCommunication"] = UC_JM_InterThreadCommunication





