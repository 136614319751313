import React from "react";
import Table from "../../../../src/components/table/Table";


const TableOperators = () => {
    return (
    <Table headers={tableHeaders} rows={tableData}/> 
    );
}
export default TableOperators;


const tableHeaders = [
    "Operator",
    "Name",
    "Usage",
    "Example",
    "Result",
  ];
  
  const tableData = [
    {
      id : "1",
      columns: [   
        "&",
        "Bitwise AND",
        "num1 & num2",
        "7 & 10",
        "2",
      ]
    },
    {
      id : "2",
      columns: [   
        "|",
        "Bitwise OR",
        "num1 | num2",
        "7 | 10",
        "15",
      ]
    },
    {
      id : "3",
      columns: [   
        "^",
        "Bitwise XOR",
        "num1 ^ num2",
        "7 ^ 10",
        "13",
      ]
    },
    {
      id : "4",
      columns: [   
        "~",
        "One's Compliment (or) Unary Operator",
        "~ num1",
        "~ 7",
        "-8",
      ]
    },
    {
      id : "5",
      columns: [   
        "<<",
        "Left Shift",
        "num1 << 2",
        "7 << 2",
        "28",
      ]
    },
    {
      id : "6", 
      columns: [   
        ">>",
        "Right Shift",
        "num1 >> 2",
        "7 >> 2",
        "1",
      ]
    },
  ];