import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import PostTags from "../tags/postTags";
import { GatsbyImage, getImage, } from "gatsby-plugin-image"
import styled from '@emotion/styled';
import { GoogleAdSense } from "../ads/GoogleAdSense";


const PostList = ({posts}) => {
  // console.log("posts>>>", posts)  
  
  const {allFile} = homePageImages()
  const nodes = allFile.nodes
  var imageDataMap = {};

  nodes.map((node, key) => {
    imageDataMap[node.relativePath] = node
  })
  // console.log("imageDataMap >>>", imageDataMap)

  return (
    <>
      {
        posts.map((post, key) => (
          <>
          <Post key={key} num={key+3} post={post} imageDataMap={imageDataMap}/>
          </>
        ))
      }
      <div className="container">
          <nav className="pagination" role="navigation">
              <ul>
                  <p>
                      <span className="page-number">Page 1</span>
                  </p>
              </ul>
          </nav>
      </div>
    </>
  )
}

const Post = ({num, post, imageDataMap}) => {
  const slug = post.fields.slug
  const tags = post.frontmatter.tags
  const title = post.frontmatter.metaTitle
  const timeToRead = post.timeToRead
  const metaDescription = post.frontmatter.metaDescription
  const date = post.frontmatter.date
  const excerpt = post.excerpt
  const node = imageDataMap[post.frontmatter.img]

  return(
    <>
      <StyledPostDiv>
        <StyledPostData>
          <StyledImageDiv>
            <a href={slug}>
              {
                node ? 
                <GatsbyImage
                  fluid={node.childImageSharp.fluid}
                  alt={node.relativePath}
                  image={getImage(node)}
                /> : null
              }
            </a>
          </StyledImageDiv>
          <StyledPostContent>
            <PostTags tags={tags} showTagsTitle={false}/>
            <StyledTitle>
              <a href={slug}>
                  {title}
              </a>
            </StyledTitle>
            <StyledExcerpt>
              {excerpt}
            </StyledExcerpt>
          </StyledPostContent>
        </StyledPostData>
      </StyledPostDiv>
      <AdBlock num={num}/>
 </>
)}

const AdBlock = ({num}) => {
  const postNumber = parseInt(num)
  const showAdv = postNumber%5===0 ? true : false
  // console.log("showAdv >>>> "+showAdv)
  if (showAdv) {
    return(
      <GoogleAdSense type={"IN_FEED"} />
    )
  }else {
    return <></>
  }
}

export default PostList;

export const StyledPostDiv = styled.div`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 3rem;

  ::before,::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb
  }
`

export const StyledPostData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media(min-width: 768px) { 
    flex-direction: row;
  }

  @media(min-width: 1024px) {
    width:83.333333%;
  }
`

export const StyledPostContent = styled.div`
  flex: 1;
  margin-top: -5px;
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`

export const StyledImageDiv = styled.div`
  @media(min-width: 768px) {
    margin-right: 1rem;
    margin-bottom: .5rem;
    /* margin-bottom: 0; */
    width: 33.333333%;
  }
`

export const StyledPostListItem1 = styled.div`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
`

export const StyledTitle = styled.h2`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 600;
  margin-top: -14px;
  margin-bottom: .25rem;
  color: ${props => props.theme.colors.tagsPostTitleColor};
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;

  a{
    color: ${props => props.theme.colors.tagsPostTitleColor};
  }

  a:hover {
    color: ${props => props.theme.colors.tagsPostTitleHOverColor};
  }
`

export const StyledExcerpt = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  color: ${props => props.theme.colors.tagsPostExcerptColor};
  margin-bottom: 1rem;
`










export const homePageImages = () => {
  const { allFile} = useStaticQuery( graphql`
    query imageDataQuery {
      allFile(
          filter: {
            sourceInstanceName: {eq: "home_images"}, 
          }
        ) {
          nodes {
            base
            childImageSharp {
              gatsbyImageData
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            absolutePath
            relativePath
          }
        }
      }
    `
  )
  return {allFile}
}
  
  
 