import React from "react";
import Table from "../../../../src/components/table/Table";

const MiddleOfTheLinkedListComplexityAnalysis = () => {
  const headers = [
    "Approach",
    "Time Complexity",
    "Space Complexity",
    "Remarks",
  ];
  
  const rows = [
    {
      id : "1",
      columns: [   
        "Naïve Approach (Using Number of Nodes)",
        "O(n)",
        "O(1)",
        "Two Traversals Needed",
      ]
    },
    {
      id : "2",
      columns: [   
        "Using Two Pointer Technique",
        "O(n)",
        "O(1)",
        "Single Traversal",
      ]
    },
  ];
  
  return (
    <Table headers={headers} rows={rows}/> 
  );
}
export default MiddleOfTheLinkedListComplexityAnalysis;