import Table from "../../../../src/components/table/Table";



const SearchingAlgorithmsComparison = () => {
    return (
        <Table headers={tableHeaders} rows={tableData}/> 
    );
}

export default SearchingAlgorithmsComparison;


const tableHeaders = [
    "Linear Search	",
    "Binary Search",
];

const tableData = [
    {
        id : "1",
        columns: [   
        "Linear Search works even if elements are in random order",
        "Binary Search works only if the elements are in sorted array",
        ]
    },
    {
        id : "2",
        columns: [   
        "In Linear Search, elements in the array will be checked one by one until we find the desired element",
        "In Binary Search random element will be checked and based on the values of random element further search process gets executed",
        ]
    },
    {
        id : "3",
        columns: [   
        "As elements checked sequentially, it is known as sequential search",
        "every iteration the array size gets reduced to half of its size, so it is known as interval search or logarithmic search",
        ]
    },
    {
        id : "4",
        columns: [   
        "Worst case time complexity is O(n)",
        "Worst case time complexity is O(log n)",
        ]
    },
    {
        id : "5",
        columns: [   
        "Linear search is efficient if array is smaller in size",
        "Binary search works efficient irrespective of the size of the array",
        ]
    },
]