import React from "react";
import Table from "../../../../src/components/table/Table";

const KthNodeFromEndOfLinkedListComplexityAnalysis = () => {

  const tableHeaders = [
    "Approach",
    "Time Complexity",
    "Space Complexity",
    "Remarks",
  ];
  
  const tableData = [
    {
      id : "1",
      columns: [   
        "By Finding Length of the Linked List",
        "O(n)",
        "O(1)",
        "Two Traversals",
      ]
    },
    {
      id : "2",
      columns: [   
        "Using Two Pointer Technique",
        "O(n)",
        "O(1)",
        "Single Traversal",
      ]
    },
  ];
  return (
    <Table headers={tableHeaders} rows={tableData}/> 
  );
}
export default KthNodeFromEndOfLinkedListComplexityAnalysis;

