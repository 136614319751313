import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PostList from './PostList';

const DsaProblems = () => {
  const {allMdx} = dsaProblemsPageData()
  const posts = allMdx.nodes;
  // console.log("posts", posts)

  return (
    <>
    <PostList posts={posts}/>
    </>
  )
}
export default DsaProblems

const dsaProblemsPageData = () => {
  const { allMdx } = useStaticQuery( graphql`
    query dsaProblemsPagePostsQuery{
      allMdx(
        filter: {frontmatter: {published: {eq: true}, category: {eq: "DSA Problems"}}}
        sort: {fields: [frontmatter___category, frontmatter___navbarTitle], order: ASC}
        limit: 2000
      ) {
        nodes {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            metaTitle
            metaDescription
            tags
            img
          }
        }
      }
    }
    `
  )
  return {allMdx}
}
