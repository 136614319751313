import React from "react";
import Table from "../../../../src/components/table/Table";

const StringReverseComplexityAnalysis = () => {
  const tableHeaders = [
    "Approach",
    "Time Complexity",
    "Space Complexity",
    "Remarks",
  ];
  
  const tableData = [
    {
      id : "1",
      columns: [   
        "Using Stack",
        "O(n)",
        "O(n)",
        "Extra Space is needed",
      ]
    },
    {
      id : "2",
      columns: [   
        "Using Two Pointer Technique",
        "O(n)",
        "O(1)",
        "No Extra Space",
      ]
    },
  ];
    
  return (
    <Table headers={tableHeaders} rows={tableData}/> 
  );
}
export default StringReverseComplexityAnalysis;

