import React from "react";
import Table from "../../../../src/components/table/Table";

const TableLanguageComparison = () => {
    return (
    <Table headers={tableHeaders} rows={tableData}/> 
    );
}
export default TableLanguageComparison;

const tableHeaders = [
    "Go",
    "Python",
    "Java",
    "C++",
  ];
  
  const tableData = [
    {
      id : "1",
      columns: [   
        "Compiled",
        "Interpreted",
        "Compiled & Interpreted",
        "Compiled",
      ]
    },
    {
      id : "2",
      columns: [ 
      "Statically typed",
      "Dynamically typed",
      "Statically typed",
      "Statically typed",
      ]
    },
    {
      id : "3",
      columns: [ 
        "Fast run time",
        "Slow run time",
        "Slow run time",
        "Fast run time",
      ]
    },
    {
      id : "4",
      columns: [ 
        "Fast compile time",
        "Slow compile time",
        "Fast compile time",
        "Slow  compile time",
      ]
    },
    {
      id : "5",
      columns: [ 
        "Has automatic garbage collection",
        "Has automatic garbage collection",
        "Has automatic garbage collection",
        "Does not support automatic garbage collection",
      ]
    },
    {
      id : "6", 
      columns: [ 
        "Does not support classes and objects",
        "Has classes and objects",
        "Has classes and objects ",
        "Has classes and objects ",
      ]
    },
    {
      id : "7",
      columns: [ 
        "Does not support inheritance",
        "Supports inheritance",
        "Supports inheritance",
        "Supports inheritance",
      ]
    },
    {
      id : "8",
      columns: [ 
        "Does not support generics",
        "Supports generics",
        "Supports generics ",
        "Supports generics ",
      ]
    },{
      id : "9",
      columns: [ 
        "Supports concurrency through goroutines and channel",
        "No built-in concurrency mechanism",
        "Supports concurrency through threads",
        "Supports concurrency through threads ",
    ]
  },
  ];