import React from "react";
import Table from "../../../../src/components/table/Table";

const SquareRootOfAnIntegerComplexityAnalysis = () => {
  const headers = [
    "Approach",
    "Time Complexity",
    "Space Complexity",
  ];
  const rows = [
    {
      id : "1",
      columns: [   
        "Using Linear Search",
        "O(√n)",
        "O(1)",
      ]
    },
    {
      id : "2",
      columns: [   
        "Using Binary Search",
        "O( log(n) )",
        "O(1)",
      ]
    },
  ];
  return (
    <Table headers={headers} rows={rows}/> 
  );
}
export default SquareRootOfAnIntegerComplexityAnalysis;


  
  