import React from 'react';
import { kebabCase } from 'lodash'
import { StyledTagList, TagItem, TagsTitle } from './tagStyles';
import Link from '../links/link';

const PostTags = ({tags, isPost}) => {
    return (
        <>   
        {
            isPost && tags && tags.length > 0 ?  <TagsTitle>Tagged</TagsTitle> : null
        }
        {/* <StyledTagList> */}
            { tags && tags.map((tag) => (
              <TagItem key={tag}>
                <Link to={`/tags/${kebabCase(tag)}/`} className="tag">
                    {tag}
                </Link>
              </TagItem>
            ))}
        {/* </StyledTagList> */}
        </>
    )
}
export default PostTags
