import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PostList from './PostList';

const Home = () => {
  const {allMdx} = homePageData()
  const posts = allMdx.nodes;
  // console.log("posts", posts)

  return (
    <>
    <PostList posts={posts}/>
    </>
  )
}
export default Home;


const homePageData = () => {
  const { allMdx } = useStaticQuery( graphql`
    query homePagePostsQuery{
      allMdx(
        filter: {frontmatter: {onIndexPage: {eq: true}, published: {eq: true}}}
        sort: { fields: [frontmatter___category, frontmatter___navbarTitle], order: ASC }
        limit: 2000
      ) {
        nodes {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            metaTitle
            metaDescription
            tags
            img
          }
        }
      }
    }
    `
  )
  return {allMdx}
}
