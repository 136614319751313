import Table from "../../../../src/components/table/Table";


const TableNumberRepresentation = () => {
    return (
        <Table headers={tableHeaders} rows={tableData}/> 
    );
}

export default TableNumberRepresentation;


const tableHeaders = [
    "Number System",
    "Base",
    "Symbols Used",
    "Example",
    "In Decimal Representation",
];

const tableData = [
    {
        id : "1",
        columns: [   
        "Decimal Number System",
        "10",
        "0,1,2,3,4,5,6,7,8,9",
        "(175)₁₀",
        "",
        ]
    },
    {
        id : "2",
        columns: [   
            "Binary Number System",
            "2",
            "0,1",
            "(10101111)₂",
            "(175)₁₀"
        ]
    },
    {
        id : "3",
        columns: [   
            "Octal Number System",
            "8",
            "0,1,2,3,4,5,6,7",
            "(257)₈",
            "(175)₁₀"
        ]
    },
    {
        id : "4",
        columns: [   
            "Hexadecimal Number System",
            "16",
            "0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F",
            "(AF)₁₆",
            "(175)₁₀"
        ]
    },
]