import Table from "../../../../src/components/table/Table";



const SortingAlgorithmsComparison = () => {
    return (
        <Table headers={tableHeaders} rows={tableData}/> 
    );
}

export default SortingAlgorithmsComparison;


const tableHeaders = [
    "Algorithm",
    "Best Case Time Complexity",
    "Average Case Time Complexity",
    "Worst Case	Time Complexity",
    "Space Complexity",
];

const tableData = [
    {
        id : "1",
        columns: [   
        "Bubble Sort",
        "   O(n)    ",
        "   O(n^2)  ",
        "   O(n^2)  ",
        "   O(1)    ",
        ]
    },
    {
        id : "2",
        columns: [   
            "Selection Sort",
            "	O(n^2)  ",
            "	O(n^2)  ",
            "   O(n^2)	",
            "   O(1)    ",
        ]
    },
    {
        id : "3",
        columns: [   
        "Insertion Sort",
        "   O(n)    ",
        "	O(n^2)  ",
        "	O(n^2)	",
        "   O(1)    ",
        ]
    },
    {
        id : "4",
        columns: [   
        "Merge Sort",
        "	O(n log n) ",
        "	O(n log n) ",
        "   O(n log n)	",
        "   O(n)    ",
        ]
    },
    {
        id : "5",
        columns: [   
        "Quick Sort	",
        "   O(n log n)  ",
        "	O(n log n)  ",
        "	O(n^2)	",
        "   O(log n)"   ,
        ]
    },
    {
        id: "6",
        columns :[
            "Heap Sort",
            "	O(n log n)  ",
            "	O(n log n)	",
            "   O(n log n)	",
            "   O(1)    "
        ]
    }
]