import React from "react";
import Table from "../../../../src/components/table/Table";


const TableOperatorXOR = () => {
    return (
    <Table headers={tableHeaders} rows={tableData}/> 
    );
}
export default TableOperatorXOR;

const tableHeaders = [
    "X",
    "Y",
    "X ^ Y",
  ];
  
  const tableData = [
    {
      id : "1",
      columns: [   
        "0",
        "0",
        "0",
      ]
    },
    {
      id : "2",
      columns: [   
        "0",
        "1",
        "1",
      ]
    },
    {
      id : "3",
      columns: [   
        "1",
        "0",
        "1",
      ]
    },
    {
      id : "4",
      columns: [   
        "1",
        "1",
        "0",
      ]
    },
  ];