import React from "react";
import Table from "../../../../src/components/table/Table";

const SingleNumberInAnArrayComplexityAnalysis = () => {
  const headers = [
    "Approach",
    "Time Complexity",
    "Space Complexity",
    "Remarks"
  ];
  
  const rows = [
    {
      id : "1",
      columns: [   
        "Naïve Solution",
        "O(n²)",
        "O(1)",
        "Iterating array in a loop"
      ]
    },
    {
      id : "2",
      columns: [   
        "Using Sorting",
        "O( n * log(n) )",
        "O(1)",
        "Sorting Algorithm Complexity"
      ]
    },
    {
      id : "2",
      columns: [   
        "Using Map",
        "O(n)",
        "O(n)",
        "Map uses extra space"
      ]
    },
    {
      id : "4",
      columns: [   
        "Using Bitwise XOR",
        "O(n)",
        "O(1)",
        "Applies Bitwise XOR by traversing the array "
      ]
    },
  ];

  return (
    <Table headers={headers} rows={rows}/> 
  );
}
export default SingleNumberInAnArrayComplexityAnalysis;

