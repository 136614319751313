
import React from "react"
import Link from "../links/link";

const PostListBasic = ({posts, urlToIgnore}) => {
    // console.log("posts >>> ", posts)
    // console.log("urlToIgnore >>> ", urlToIgnore)
    return (
        <>
            <ul>
            {
                posts.map((post, key) => (    
                    <PostItemLink 
                        key={key} 
                        post={post} 
                        urlToIgnore={urlToIgnore}
                    />
                ))
            }
            </ul>
        </>
    )
};

export default PostListBasic;

const PostItemLink = ({post, urlToIgnore}) => {
    const slug = post.fields.slug
    const title = post.frontmatter.title

    if (slug == urlToIgnore){
        return (<></>)
    }else {
        return (
            <>
                <li>
                <Link to={slug}>{title}</Link>
                </li>
            </>
        )
    }
}