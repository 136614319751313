import React  from "react";
import './table.css'

const defaultId = "data"
const Table = ({headers, rows, id}) => {
    // console.log("id is", id)
    if (typeof id === 'undefined') {
        id = defaultId
    }
    // console.log("updated id is", id)
    var rowCount = 0
    return(
        <table id={id}>
            <thead id={id}>
                <TableHeadItem id={id} headers={headers}/>
            </thead>
            <tbody id={id}>{
                    rows.map((row) => {
                        return <TableRow id={id} key={rowCount++} columns={row.columns}/>
                })}
            </tbody>
        </table>
    )
}

const TableRow = ({ columns, id }) => {
    var colCount = 0
    return (
        <tr id={id}>{
                columns.map( (column) => {
                    return <td id={id} key={colCount++}>{column}</td>;
        })}
        </tr>
    );
}


const TableHeadItem = ({ headers, id }) => {
    return (
        <tr id={id}>{
            headers.map((header) => {
                return <th key={header}>{header}</th>
        })}
        </tr>
    );
}

export default Table

