import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PostListBasic from './PostListBasic';
import { UC_JavaMultithreading } from '../links/PageLink';

const JavaMultithreading = () => {
  const {allMdx} = javaMultithreadingPageData()
  const posts = allMdx.nodes;
  return (
    <>
        <PostListBasic posts={posts} urlToIgnore={UC_JavaMultithreading}/>
    </>
  )
}
export default JavaMultithreading

const javaMultithreadingPageData = () => {
  const { allMdx } = useStaticQuery( graphql`
    query javaMultithreadingPageDataQuery{
      allMdx(
        filter: {frontmatter: {published: {eq: true}, category: {eq: "Java Multithreading"}}}
        sort: {fields: [frontmatter___category, frontmatter___navbarTitle], order: ASC}
        limit: 2000
      ) {
        nodes {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            metaTitle
            metaDescription
            tags
            img
          }
        }
      }
    }
    `
  )
  return {allMdx}
}
