import React from 'react';
import { kebabCase } from 'lodash'
import { Link, graphql, useStaticQuery } from 'gatsby';
import { StyledTagList, TagItem } from './tagStyles';

export const AllTags = () => {
  const {allMdx} = allTagsData()
  const tags = allMdx.group;
  // console.log("tags", tags)
    return(
      <>
        <StyledTagList>
          {tags.map((tag) => (
            <TagItem key={tag.fieldValue}>
                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className="tag">
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
            </TagItem>
          ))}
        </StyledTagList>
        </>
    )
}


export const allTagsData = () => {
  const { allMdx} = useStaticQuery( graphql`
    query allTagsQuery{
      allMdx(limit: 2000) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
    `
  )
  return {allMdx}
}